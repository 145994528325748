import $ from 'jquery';

const main = {
    init: function() {
        const importantDate = $("#mainImportantDate").val().split("T")[0];

        const importantYear = importantDate.split("-")[0];
        const importantMonth = importantDate.split("-")[1];
        const importantDay = importantDate.split("-")[2];

        $(".lastNoticeYm").text(`${importantYear}.${importantMonth}`);
        $(".lastNoticeDay").text(`${importantDay}`);

        $(".rentMove").on("click", function() {
            const room = $(this).data("room");
            location.href=`/rent/entry`;
        });

        $(".swiper-rent-button-prev").on("click", function() {
            const tabIndex = $(this).attr("tabindex");
            if(!$(this).hasClass("none")) {
                if(tabIndex === -1) {
                    $(this).addClass("none");
                }
            }


            if($(".swiper-rent-button-next").hasClass("none")) {
                $(".swiper-rent-button-next").removeClass("none");
            }
        })

        $(".swiper-rent-button-next").on("click", function() {
            const tabIndex = $(this).attr("tabindex");
            if(!$(this).hasClass("none")) {
                if(tabIndex === -1) {
                    $(this).addClass("none");
                }
            }

            if($(".swiper-rent-button-prev").hasClass("none")) {
                $(".swiper-rent-button-prev").removeClass("none");
            }
        })
    },
};
main.init();
